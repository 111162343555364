* {
    margin: 0;
    padding: 0;
}
html,
body {
    cursor: grab;
    overflow: hidden;
    width: 100%;
    height: 100%;
}


.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 20px;

    /* padding-left: 70px; */
    /* display: block; */
    /* position: absolute; */
    position: relative;
    justify-content: center;


    top: 50%;
    /* left:18%; */
    left:50%;

    -webkit-transform: translate(-50%,-50%);
  }

  h1 a {
    position: relative;
    text-align: center;
    color: #fff;
    font-size: 1em;
    text-decoration: none;
    /* display: inline-block; */
    /* position: relative; */
    padding-left: 60px;
    padding-right: 60px;
    text-align: center;
    margin: auto;
    animation: fadeIn4 4s;
    font-family: 'Dosis', sans-serif;
    padding-top: 10px;
    

  }

  a.effect-underline:after {
	content: '';
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(0,1);
	transform: scale(0,1);
}

a.effect-underline:hover:after {
  opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}



.point .label {
    position: absolute;
    text-align: center;
    margin: auto;
    top: -20px;
    left: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    line-height: 20px;
    font-weight: 100;
    font-size: 14px;
    opacity: 0;

}

.point .text {
    /* position: absolute; */
    text-align: center;
    margin: auto;
    /* top: 30px; */
    /* left: -70px; */
    width: 100px;
    padding: 20px;
    border-radius: 4px;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    line-height: 0.3em;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 10px;
    opacity: 0.3;
    transition: opacity 0.7s;
    cursor: pointer;
}

.point:hover .text {
    opacity: 1;
}
.point2 .label {
    position: absolute;
    text-align: center;
    margin: auto;
    top: -20px;
    left: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    line-height: 20px;
    font-weight: 100;
    font-size: 14px;
    opacity: 0;

}

.point2 .text {
    /* position: absolute; */
    text-align: center;
    margin: auto;
    animation: fadeIn3 5s;

    /* top: 30px; */
    /* left: -70px; */
    width: 150px;
    padding: 20px;
    border-radius: 4px;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    line-height: 0.3em;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 10px;
    opacity: 0.3;
    transition: opacity 0.7s;
    cursor: pointer;
}

.point2:hover .text {
    opacity: 1;
}

.point3 .label {
    position: absolute;
    text-align: center;
    margin: auto;
    top: -20px;
    left: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    line-height: 20px;
    font-weight: 100;
    font-size: 14px;
    opacity: 0;

}

.point3 .text {
    /* position: absolute; */
    text-align: center;
    margin: auto;
    animation: fadeIn3 10s;

    /* top: 30px; */
    /* left: -70px; */
    width: 100px;
    padding: 20px;
    border-radius: 4px;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    line-height: 0.3em;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 10px;
    opacity: 0.3;
    transition: opacity 0.7s;
    cursor: pointer;
}

.point3:hover .text {
    opacity: 1;
}


.point4 .label {
    position: absolute;
    text-align: center;
    margin: auto;
    top: -20px;
    left: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    line-height: 20px;
    font-weight: 100;
    font-size: 14px;
    opacity: 0.6;

}

.point4 .text {
    /* position: absolute; */
    text-align: center;
    margin: auto;
    animation: fadeIn5 10s;

    /* top: 30px; */
    /* left: -70px; */
    width: 100px;
    padding: 20px;
    border-radius: 4px;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    line-height: 0.3em;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 10px;
    opacity: 0.6;
    transition: opacity 0.7s;
    cursor: pointer;
}

.point4:hover .text {
    opacity: 1;
}

#container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    text-align: center;
    color: white;
    margin: 5px;
    gap: 6px;

}
#conainerYo{
    display:flex;
    flex-direction: row;
}

#container2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: auto;
    text-align: center;
    
}
#container3 {
    display: flex;
    flex-direction: row; 
    /* justify-content: center;  */
    width: 100%;
    height: auto;
    /* text-align: center; */
    top: 0;
    left: 0;
    color: white;
}
#created {
    position: fixed;
    animation: fadeIn2 11s;
    /* transition: 11s; */
    opacity: 0.5;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 10px;
    color: white;
    bottom: 0;
    right: 0;
    text-decoration: none;
}
#created:hover {
    opacity: 1;
    transition: opacity 1s;
}

#logo {
    position: relative;
    animation: fadeIn 6s;
    opacity: 0.18;
    padding-top: 20px;
    padding-bottom: 10px;
}

.point, #virtual-tour-btn {
    position: relative;
    opacity: 0;
    transition: opacity 3s;
}



#logo:hover {
    opacity: 1;
    transition: opacity 1s;
}

.div-1 {
    /* background-color: blue; 
    height: 50px;
    width: 50px; */
    position: absolute;
    /* animation: fadeIn 6s; */
    /* top: 1%; 
	left:1%;  */
    bottom: 5%;
    /* right:1%; */
    right: 5%;
    /* opacity: 0.18; */
}

.div-1 .text {
    position: absolute;
    text-align: center;
    margin: auto;

    top: 1px;
    left: -70px;
    width: 100px;
    padding: 20px;
    /* border-radius: 4px; */
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    line-height: 0.3em;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 10px;
    opacity: 0.3;
    transition: opacity 0.7s;
    cursor: pointer;
}

#tal {
    position: absolute;
    animation: fadeIn 6s;
    /* top: 1%; 
	left:1%;  */
    bottom: 1%;
    /* right:1%; */
    right: 10%;
    opacity: 0.18;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    100% {
        opacity: 0.18;
    }
}

@keyframes fadeIn2 {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }
    75%{
        opacity: 0;
    }
    80%{
        opacity: 0.1;
    }
    85%{
        opacity: .2;
    }
    90%{
        opacity: .3;
    }
    95%{
        opacity: .4;
    }

    100% {
        opacity: 0.5;
    }
}
@keyframes fadeIn3 {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.15;
    }

    100% {
        opacity: 0.3;
    }
}
@keyframes fadeIn4 {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}
@keyframes fadeIn5 {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 0.6;
    }
}
@keyframes fadeOut4 {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
    }
}

/* @keyframes fadeIn {
    0% { opacity:0; }
    50% { opacity:0.5; } 
    100% { opacity:1; } 
   } */

.demo,
.demo p {
    margin: 4em 0;
    text-align: center;
}
